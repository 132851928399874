<template>
    <div id="app-dropdown" class="card dropdown-content hide-on-small-and-down appdropdown right z-depth-1-half" tabindex="0">
        <div class="card-content text-left">
            <span class="card-title grey-text text-darken-4 center">
                <img src="https://core.lukas-bownik.net/images/pika-logos/pikacloud_apps.svg" alt="Pika"/>
            </span>
            <div class="collection borderless-collection">
                <a class="collection-item navlink app-menu-item" href="https://core.lukas-bownik.net/" title="Pika Core">
                    <span class="material-icons secondary-content navlink red-text text-accent-2">
                        search
                    </span>
                    Pika Core
                </a>
                <a class="collection-item navlink app-menu-item" href="https://player.lukas-bownik.net/" title="Pika Player">
                    <span class="material-icons secondary-content navlink red-text text-accent-2">
                        play_arrow
                    </span>
                    Pika Player
                </a>
                <a class="collection-item navlink app-menu-item" href="https://infra.lukas-bownik.net/" title="Pika Status">
                    <span class="material-icons secondary-content navlink red-text text-accent-2">
                        favorite_border
                    </span>
                    Pika Status
                </a>
            </div>
        </div>
    </div>
</template>